* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 1rem;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white;
}

h1 {
  font-weight: 800;
  font-size: 1.5rem;
}
